import { Mic, MicOff, Video, VideoOff } from "lucide-react";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import SimplePeer from "simple-peer";
import { PaperAirplaneIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { AuthContext } from "../../context/AuthContext";
import { SettingContext } from "../../context/SettingContext";

const VideoCallRoom = ({
  room,
  setRoom,
  socket,
  onLeaveRoom,
  setActiveTab,
}) => {
  const { user } = useContext(AuthContext);
  const settingInfo = useContext(SettingContext);
  const streamRef = useRef(null);
  const messagesEndRef = useRef(null);
  const localVideoRef = useRef(null);
  const peersRef = useRef({});
  const [remoteStreams, setRemoteStreams] = useState({});
  const remoteStreamsRef = useRef({});
  const [participants, setParticipants] = useState(room.participants);
  const [loadingParticipants, setLoadingParticipants] = useState(false);
  const [isCamOn, setIsCamOn] = useState(false);
  const [isMicOn, setIsMicOn] = useState(false);
  const [message, setMessage] = useState("");
  const [roomMessages, setRoomMessages] = useState([]);
  const placeholderStreamInitialized = useRef(false);

  const canvasRef = useRef(document.createElement("canvas"));
  const contextRef = useRef(null);
  const placeholderStreamRef = useRef(null);

  const initializePlaceholderStream = () => {
    if (placeholderStreamInitialized.current)
      return placeholderStreamRef.current;

    const ctx = contextRef.current;
    if (!ctx) return null;
    ctx.fillStyle = "black";
    ctx.fillRect(0, 0, canvasRef.current.width, canvasRef.current.height);

    // Add text
    ctx.fillStyle = "white";
    ctx.font = "24px Arial";
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";

    // Create stream if it doesn't exist
    if (!placeholderStreamRef.current) {
      placeholderStreamRef.current = canvasRef.current.captureStream(30);
      // Add silent audio track
      const audioContext = new (window.AudioContext ||
        window.webkitAudioContext)();
      const oscillator = audioContext.createOscillator();
      const dst = audioContext.createMediaStreamDestination();
      oscillator.connect(dst);
      const audioTrack = dst.stream.getAudioTracks()[0];
      audioTrack.enabled = false;
      placeholderStreamRef.current.addTrack(audioTrack);
    }
    localVideoRef.current.srcObject = placeholderStreamRef.current;
    placeholderStreamInitialized.current = true;
    return placeholderStreamRef.current;
  };

  useEffect(() => {
    // Initialize canvas only once
    const canvas = canvasRef.current;
    canvas.width = 280;
    canvas.height = 240;
    contextRef.current = canvas.getContext("2d");
    // Create initial placeholder stream
    initializePlaceholderStream();
    return () => {
      if (placeholderStreamRef.current) {
        placeholderStreamRef.current
          .getTracks()
          .forEach((track) => track.stop());
      }
    };
  }, []);

  const toggleCamera = async () => {
    try {
      console.log("Click toggle camera");
      const newCamState = !isCamOn;
      setIsCamOn(newCamState);
      socket.emit("updateVideoState", {
        roomId: room.roomId,
        videoState: newCamState,
        senderId: socket.id,
      });
      // Stop existing tracks before switching
      if (streamRef.current) {
        streamRef.current.getTracks().forEach((track) => {
          track.stop();
        });
      }
      if (newCamState) {
        const stream = await startCamera();
        if (stream) {
          console.log("update peers with stream when start camera");
          await updatePeersWithStream(stream);
        }
      } else {
        console.log("stop camera");
        const placeholderStream = initializePlaceholderStream();
        if (placeholderStream) {
          streamRef.current = placeholderStream;
          localVideoRef.current.srcObject = placeholderStream;
          await updatePeersWithStream(placeholderStream);
        }
      }
    } catch (err) {
      console.error("Error toggling camera:", err);
      setIsCamOn(false);
      // Fallback to placeholder stream on error
      const placeholderStream = initializePlaceholderStream();
      if (placeholderStream) {
        streamRef.current = placeholderStream;
        localVideoRef.current.srcObject = placeholderStream;
        await updatePeersWithStream(placeholderStream);
      }
    }
  };
  const toggleMicrophone = async () => {
    const newMicState = !isMicOn;

    try {
      // Only request microphone access when turning it on
      if (newMicState) {
        const audioStream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        const audioTrack = audioStream.getAudioTracks()[0];

        // Replace the dummy audio track in the current stream
        const currentStream = localVideoRef.current.srcObject;
        const oldAudioTrack = currentStream.getAudioTracks()[0];
        if (oldAudioTrack) {
          oldAudioTrack.stop();
          currentStream.removeTrack(oldAudioTrack);
        }
        currentStream.addTrack(audioTrack);

        // Update all peer connections with the new audio track
        Object.values(peersRef.current).forEach((peer) => {
          const audioSender = peer._pc
            .getSenders()
            .find((s) => s.track?.kind === "audio");
          if (audioSender) {
            audioSender.replaceTrack(audioTrack);
          }
        });
      } else {
        // When turning off, stop the real audio track and replace with dummy
        const currentStream = localVideoRef.current.srcObject;
        const audioTrack = currentStream.getAudioTracks()[0];
        if (audioTrack) {
          audioTrack.stop();
          currentStream.removeTrack(audioTrack);
        }

        // Add dummy audio track
        const dummyStream = createPlaceholderStream();
        const dummyAudioTrack = dummyStream.getAudioTracks()[0];
        currentStream.addTrack(dummyAudioTrack);

        // Update peers with dummy track
        Object.values(peersRef.current).forEach((peer) => {
          const audioSender = peer._pc
            .getSenders()
            .find((s) => s.track?.kind === "audio");
          if (audioSender) {
            audioSender.replaceTrack(dummyAudioTrack);
          }
        });
      }

      setIsMicOn(newMicState);
    } catch (err) {
      console.error("Error toggling microphone:", err);
      setIsMicOn(false);
    }
  };

  const startCamera = async () => {
    try {
      console.log("Start Camera");
      // Stop any existing streams first
      if (streamRef.current) {
        streamRef.current.getTracks().forEach((track) => track.stop());
      }

      const stream = await navigator.mediaDevices.getUserMedia({
        video: {
          width: "100%",
          height: "100%",
          frameRate: { ideal: 30 },
        },
        audio: isMicOn,
      });
      streamRef.current = stream;

      // Ensure video track is enabled
      const videoTrack = stream.getVideoTracks()[0];
      if (videoTrack) {
        videoTrack.enabled = true;
      }

      // Set stream to video element
      if (localVideoRef.current) {
        localVideoRef.current.srcObject = stream;
      }

      return stream;
    } catch (err) {
      console.error("Error accessing camera:", err);
      throw err;
    }
  };
  const updatePeersWithStream = async (stream) => {
    try {
      console.log("Update peers with this stream", stream);
      Object.entries(peersRef.current).forEach(([peerId, peer]) => {
        const senders = peer._pc.getSenders();
        console.log("senders", senders);

        const videoTrack = stream.getVideoTracks()[0];
        const sender = senders.find((s) => s.track?.kind === "video");

        if (sender) {
          sender.replaceTrack(videoTrack);
        }

        // Update remote streams ref and state
        // setRemoteVideoStream(peerId, stream, isCamOn);
      });
    } catch (err) {
      console.error("Error updating peers with stream:", err);
    }
  };
  // const updatePeersWithStream = async (stream) => {
  //   try {
  //     console.log("Update peers with this stream", stream);
  //     const promises = Object.values(peersRef.current).map(async (peer) => {
  //       const videoTrack = stream.getVideoTracks()[0];
  //       const audioTrack = stream.getAudioTracks()[0];

  //       const senders = peer._pc.getSenders();
  //       console.log("senders", senders);

  //       const videoSender = senders.find((s) => s.track?.kind === "video");
  //       const audioSender = senders.find((s) => s.track?.kind === "audio");
  //       if (videoSender && videoTrack) {
  //         await videoSender.replaceTrack(videoTrack);
  //       }
  //       if (audioSender && audioTrack) {
  //         await audioSender.replaceTrack(audioTrack);
  //       }
  //     });

  //     await Promise.all(promises);
  //   } catch (err) {
  //     console.error("Error updating peers with stream:", err);
  //     throw err;
  //   }
  // };

  useEffect(() => {
    // Initialize with placeholder stream when component mounts
    console.log("initializePlaceholderStream");
    // initializePlaceholderStream();

    // Listen for room updates
    socket.on("updatedRoom", (updatedRoom) => {
      if (room && room.roomId === updatedRoom.roomId) {
        setParticipants(updatedRoom.participants);
      }
    });

    // When a user joins the room
    socket.on("joinedRoom", async (room) => {
      console.log("joinedRoom", room);

      // Initialize the local placeholder stream
      const initialStream = await initializePlaceholderStream();
      setLoadingParticipants(true);

      for (let participant of room.participants) {
        if (participant.socketId !== socket.id) {
          console.log("Create peer for participant", participant.socketId);
          const peer = createPeer(participant.socketId, true, initialStream);
          peersRef.current[participant.socketId] = peer;
        }
      }
    });

    // When receiving a signal from another user
    socket.on("receiveSignal", async ({ signal, senderSocketId }) => {
      let peer = peersRef.current[senderSocketId];
      setLoadingParticipants(true);

      if (!peer) {
        console.log("Create peer for sender", senderSocketId);

        // Use placeholder stream if no local stream exists
        const currentStream =
          streamRef.current || (await initializePlaceholderStream());
        peer = createPeer(senderSocketId, false, currentStream);
        peersRef.current[senderSocketId] = peer;
      }

      // Add signal to the peer
      peer.signal(signal);
    });

    // Handle returned signals during WebRTC negotiation
    socket.on("returnedSignal", ({ signal, senderSocketId }) => {
      const peer = peersRef.current[senderSocketId];
      if (peer) {
        peer.signal(signal);
      }
    });

    // Handle user leaving the room
    socket.on("userLeft", ({ socketId }) => {
      if (peersRef.current[socketId]) {
        peersRef.current[socketId].destroy();
        delete peersRef.current[socketId];
      }
      removeVideoStream(socketId);
    });
    socket.on("updateVideoState", (data) => {
      console.log("Received video state update:", data);

      if (data.senderId !== socket.id) {
        remoteStreamsRef.current[data.senderId] = {
          stream: remoteStreamsRef.current[data.senderId]?.stream,
          isCamOn: data.videoState,
        };
        setRemoteStreams((prev) => ({
          ...prev,
          [data.senderId]: {
            stream: prev[data.senderId]?.stream, // Keep existing stream
            isCamOn: data.videoState, // Update camera state
          },
        }));
      }
    });
    // socket.on("updateVideoState", (data) => {
    //   console.log("update video state", data);

    //   if (data.senderId !== socket.id) {
    //     const currentStreamData = remoteStreamsRef.current[data.senderId];
    //     if (currentStreamData) {
    //       setRemoteVideoStream(
    //         data.senderId,
    //         currentStreamData.stream,
    //         data.videoState
    //       );
    //     }
    //   }
    // });
    // Cleanup on component unmount
    return () => {
      stopLocalStream();
      destroyPeersAndStreams();
      socket.off("receiveSignal");
      socket.off("userLeft");
      socket.off("returnedSignal");
      socket.off("joinedRoom");
      socket.off("updatedRoom");
      socket.off("updateVideoState");
    };
  }, [socket, room, setRemoteStreams]);

  const createPeer = (targetSocketId, isInitiator, stream) => {
    console.log("isInitiator", isInitiator);

    const peer = new SimplePeer({
      initiator: isInitiator,
      trickle: false,
      stream,
      config: {
        iceServers: [{ urls: "stun:stun.l.google.com:19302" }],
      },
    });

    peer.on("signal", (signal) => {
      console.log("signal", signal);
      if (isInitiator) {
        console.log(`send signal to ${targetSocketId}`);
        socket.emit("sendSignal", { signal, targetSocketId });
      } else {
        console.log(`return signal to ${targetSocketId}`);
        socket.emit("returnSignal", { signal, targetSocketId });
      }
    });

    // Initialize remote stream with camera off
    setRemoteStreams((prev) => ({
      ...prev,
      [targetSocketId]: {
        stream: null,
        isCamOn: false,
      },
    }));

    peer.on("stream", (remoteStream) => {
      console.log("Received stream from:", targetSocketId);
      setLoadingParticipants(false);
      // When receiving stream, update both stream and camera state
      console.log(participants);

      const participant = participants.find(
        (p) => p.socketId === targetSocketId
      );
      const remoteStreamRef = remoteStreamsRef.current[targetSocketId];
      setRemoteStreams((prev) => ({
        ...prev,
        [targetSocketId]: {
          stream: remoteStream,
          isCamOn: participant
            ? participant?.isCamOn
            : remoteStreamRef
            ? remoteStreamRef?.isCamOn
            : false, // Keep existing camera state
        },
      }));
    });

    return peer;
  };
  console.log("render", remoteStreams);

  const createPlaceholderStream = () => {
    const canvas = document.createElement("canvas");
    canvas.width = "100%";
    canvas.height = "100%";
    const ctx = canvas.getContext("2d");
    ctx.fillStyle = "black";
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    ctx.fillStyle = "white";
    ctx.font = "24px Arial";
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";
    ctx.fillText("Camera Off", canvas.width / 2, canvas.height / 2);

    const stream = canvas.captureStream(30);

    // Create a dummy audio track
    const audioContext = new (window.AudioContext ||
      window.webkitAudioContext)();
    const oscillator = audioContext.createOscillator();
    const dst = audioContext.createMediaStreamDestination();
    oscillator.connect(dst);
    const dummyAudioTrack = dst.stream.getAudioTracks()[0];
    dummyAudioTrack.enabled = false;
    stream.addTrack(dummyAudioTrack);

    return stream;
  };

  const setRemoteVideoStream = (socketId, stream, isCamOn) => {
    console.log("Setting remote video stream for user:", socketId);

    // Update ref first
    remoteStreamsRef.current[socketId] = {
      stream: stream,
      isCamOn: isCamOn,
    };

    // Then update state
    setRemoteStreams((prevStreams) => ({
      ...prevStreams,
      [socketId]: {
        stream: stream,
        isCamOn: isCamOn,
      },
    }));
  };

  const removeVideoStream = (socketId) => {
    console.log("Removing video stream for user:", socketId);
    setRemoteStreams((prevStreams) => {
      const newStreams = { ...prevStreams };
      delete newStreams[socketId];
      return newStreams;
    });
  };
  const destroyPeersAndStreams = () => {
    Object.values(peersRef.current).forEach((peer) => {
      peer.destroy();
    });
    setRemoteStreams({});
  };

  const stopLocalStream = () => {
    if (localVideoRef.current && localVideoRef.current.srcObject) {
      localVideoRef.current.srcObject
        .getTracks()
        .forEach((track) => track.stop());
    }
  };

  useEffect(() => {
    socket.on("newMessage", (message) => {
      console.log("new message receive", message.content);
      console.log("from ", message.sender);
      console.log("socketId", message.socketId);
      console.log("at", message.sentAt);
      setRoomMessages((prevMessages) => [...prevMessages, message]);
    });
    return () => {
      socket.off("message");
    };
  }, [socket]);

  const handleSendMessage = useCallback(
    (message) => {
      if (!message || message === "") return;
      const date = new Date();
      socket.emit("sendMessage", {
        content: message,
        roomId: room.roomId,
        sentAt: date,
        sender: user,
      });
      setMessage("");
    },
    [socket, room.roomId, user]
  );
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [roomMessages]);
  return (
    <div className="text-black flex flex-row divide-x-2 h-full">
      <div className="w-[75%] flex flex-col">
        <div className="flex flex-col justify-center">
          <span className="text-lg font-semibold">
            Study Room: {room?.roomName}
          </span>
          <div className="flex flex-row items-center justify-center">
            <div className="block h-2 w-2 rounded-full mr-1 bg-green-500"></div>
            <span className="text-zinc-500">
              {participants ? participants?.length : 0} users online
            </span>
          </div>
        </div>

        <div className="my-4 w-full bg-gray-200 h-0.5"></div>
        <div className="grid md:grid-cols-3 lg:grid-cols-4 grid-rows-2 w-full">
          <div className="flex flex-col w-full h-full p-4">
            <video
              ref={localVideoRef}
              autoPlay
              muted
              width={280}
              height={240}
              className="rounded-xl w-full h-full"
            />
            <span className="bg-zinc-100 p-1 rounded-lg truncate">
              {
                participants?.find((p) => p.socketId === socket.id)?.user
                  .username
              }{" "}
              (Me)
            </span>
          </div>
          <>
            {!loadingParticipants && (
              <>
                {Object.keys(remoteStreams).map((socketId) => {
                  const streamData = remoteStreams[socketId];
                  console.log("Rendering stream for:", socketId, {
                    hasStream: !!streamData?.stream,
                    isCamOn: streamData?.isCamOn,
                  });
                  if (streamData?.stream) {
                    console.log(
                      "streamData",
                      streamData.stream.getVideoTracks()
                    );
                  }

                  return (
                    <div
                      key={socketId}
                      className="flex flex-col w-full h-full p-4"
                    >
                      {/* Check if the remote participant has a valid stream */}
                      {streamData?.stream && streamData?.isCamOn ? (
                        <>
                          <video
                            id={socketId}
                            key={socketId}
                            autoPlay
                            width={280}
                            height={240}
                            ref={(el) => {
                              if (el && streamData.stream) {
                                if (el.srcObject !== streamData.stream) {
                                  el.srcObject = streamData.stream;
                                }
                              }
                            }}
                            className="rounded-xl mr-4 w-full h-full"
                          />
                          <span className="bg-zinc-100 p-1 rounded-lg truncate">
                            {
                              participants.find((p) => p.socketId === socketId)
                                ?.user.username
                            }
                          </span>
                        </>
                      ) : (
                        // Placeholder for participants with no stream (camera off)
                        <>
                          <div
                            key={socketId}
                            className="w-full h-full bg-black text-white flex justify-center items-center rounded-xl "
                          >
                            {/* Camera off */}
                          </div>
                          <span className="bg-zinc-100 p-1 rounded-lg truncate">
                            {
                              participants.find((p) => p.socketId === socketId)
                                ?.user.username
                            }
                          </span>
                        </>
                      )}
                    </div>
                  );
                })}
                {/* {Object.entries(remoteStreams).map(([socketId, stream]) => (
                  <div
                    key={socketId}
                    className="flex flex-col w-full h-full mr-2"
                  >
                   
                    <video
                      key={socketId}
                      id={socketId}
                      autoPlay
                      muted={socketId === socket.id} // Mute local video to prevent echo
                      className="rounded-xl mr-4"
                      ref={(ref) => {
                        if (ref && stream) {
                          ref.srcObject = stream;
                        }
                      }}
                    />

                    <span className="bg-zinc-100 p-1 rounded-lg mr-4">
                      {
                        participants.find((p) => p.socketId === socketId)?.user
                          .username
                      }
                    </span>
                  </div>
                ))} */}
              </>
            )}
          </>
        </div>
        {loadingParticipants && (
          <div className="flex self-center top-1/2 left-[35%] flex-col items-center absolute">
            <svg
              aria-hidden="true"
              className="w-12 h-12 text-gray-200 animate-spin dark:text-gray-600 fill-sky-400"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
            <span className="text-sm text-zinc-400">
              Loading participants in room...
            </span>
          </div>
        )}
        <div className="flex justify-center space-x-1 mt-auto">
          <div
            className="px-4 py-2 cursor-pointer bg-sky-300 rounded-lg text-white hover:bg-sky-500"
            onClick={toggleCamera}
          >
            {isCamOn ? <Video /> : <VideoOff />}
          </div>
          <div
            className="px-4 py-2 cursor-pointer bg-sky-300 rounded-lg text-white hover:bg-sky-500"
            onClick={toggleMicrophone}
          >
            {isMicOn ? <Mic /> : <MicOff />}
          </div>
          <button
            className="bg-sky-400 hover:bg-sky-500 w-fit self-center font-semibold rounded-xl py-2 px-4 text-white"
            onClick={() => {
              onLeaveRoom();
              stopLocalStream();
              destroyPeersAndStreams();
            }}
          >
            Leave Room
          </button>
        </div>
      </div>

      <div className="w-[25%] flex flex-col flex-1 h-full overflow-x-auto">
        <div className="flex flex-row items-center w-full mb-6">
          <XMarkIcon
            className={
              "h-6 w-6  hover:text-sky-400 cursor-pointer absolute right-0 mr-2 mt-1"
            }
            aria-hidden="true"
            onClick={() => setActiveTab("home")}
          />
        </div>
        <div className="bg-sky-300 h-[40px] items-center text-center flex flex-col rounded">
          <span className="mx-auto my-auto font-semibold text-white">
            Chat room
          </span>
        </div>
        <div className="p-4 message-list bg-zinc-100 space-y-2 flex flex-col flex-1 overflow-scroll break-words whitespace-normal">
          {roomMessages?.map((message, index) => {
            return message?.sender?._id !== user?._id ? (
              <div
                key={index}
                className="flex space-x-2 justify-start break-words whitespace-normal w-full"
              >
                <div className="min-w-fit h-full">
                  <img
                    alt="user-profile"
                    referrerPolicy="no-referrer"
                    className="w-8 h-8 rounded-full"
                    src={
                      message?.sender?.picture
                        ? message.sender.picture
                        : settingInfo.googleImgDefault
                    }
                  />
                </div>
                <div className="flex flex-col break-words whitespace-normal truncate bg-zinc-500 text-start text-zinc-100 rounded-lg py-2 px-3 ">
                  <div className="flex flex-row items-center text-start justify-between space-x-2">
                    <span className="text-sm text-left text-white font-semibold">
                      {message?.sender?.username
                        ? message.sender.username
                        : "Anonymous"}
                    </span>
                    <span className="text-xs text-end">
                      {message?.sentAt
                        ? new Date(message.sentAt).toLocaleTimeString([], {
                            hour: "2-digit",
                            minute: "2-digit",
                          })
                        : ""}{" "}
                    </span>
                  </div>

                  <div key={index}>{message.content}</div>
                </div>
              </div>
            ) : (
              <div
                key={index}
                className="flex space-x-2 justify-end break-words whitespace-normal"
              >
                <div className="flex flex-col break-words whitespace-normal truncate bg-blue-400 text-start text-white rounded-lg py-2 px-3">
                  <div className="flex flex-row items-center text-start justify-between space-x-2">
                    <span className="text-xs">
                      {message?.sentAt
                        ? new Date(message.sentAt).toLocaleTimeString([], {
                            hour: "2-digit",
                            minute: "2-digit",
                          })
                        : ""}{" "}
                    </span>
                    <span className="text-sm text-end text-white font-semibold">
                      {user?.username ? "You" : "Unknown User"}
                    </span>
                  </div>

                  <div key={index}>{message.content}</div>
                </div>
                <div className="min-w-fit h-full">
                  <img
                    alt="user-profile"
                    referrerPolicy="no-referrer"
                    className="w-8 h-8 rounded-full"
                    src={
                      user?.picture
                        ? user?.picture
                        : settingInfo.googleImgDefault
                    }
                  />
                </div>
              </div>
            );
          })}

          <div ref={messagesEndRef} />
        </div>
        <div className="flex flex-row items-center mt-auto">
          <input
            type="text"
            // defaultValue={text}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                handleSendMessage(message);
              }
            }}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg outline-none focus:ring-2 focus:ring-sky-300 block w-full p-2.5"
            placeholder="Type your message here"
          />
          <PaperAirplaneIcon
            className="cursor-pointer text-sky-400  w-6 h-6"
            onClick={() => handleSendMessage(message)}
          />
        </div>
      </div>
    </div>
  );
};

export default VideoCallRoom;
