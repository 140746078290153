import {
  forwardRef,
  Fragment,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  ExclamationCircleIcon,
  QuestionMarkCircleIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";

const ModalConfirmation = forwardRef((props, ref) => {
  const [open, setOpen] = useState(false);
  const [option, setOption] = useState({});
  const cancelButtonRef = useRef(null);
  useImperativeHandle(
    ref,
    () => {
      return {
        open(opt) {
          setOption(opt);
          setOpen(true);
        },
        close() {
          setOpen(false);
        },
      };
    },
    []
  );
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
      ref={props?.parentRef}
        as="div"
        className="relative z-40"
        initialFocus={cancelButtonRef}
        onClose={() => setOpen(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-[80%] items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <XMarkIcon
                  className={
                    "h-6 w-6 m-2 hover:text-sky-400 cursor-pointer right-0 absolute text-zinc-500"
                  }
                  aria-hidden="true"
                  onClick={() => setOpen(false)}
                />
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div
                      className={`mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full ${
                        option.icon === "question" ? "bg-sky-100" : "bg-red-100"
                      }  sm:mx-0 sm:h-10 sm:w-10`}
                    >
                      {option.icon === "question" ? (
                        <QuestionMarkCircleIcon
                          className="h-6 w-6 text-sky-400"
                          aria-hidden="true"
                        />
                      ) : option.icon === "exclamation" ? (
                        <ExclamationCircleIcon
                          className="h-6 w-6 text-red-400"
                          aria-hidden="true"
                        />
                      ) : (
                        <ExclamationCircleIcon
                          className="h-6 w-6 text-red-400"
                          aria-hidden="true"
                        />
                      )}
                    </div>
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left cursor-default">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900"
                      >
                        {option.title}
                      </Dialog.Title>
                      {option.content && (
                        <div className="mt-2">
                          <p className="text-sm text-gray-500">
                            {option.content}
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-sky-400 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-sky-500 sm:ml-3 sm:w-auto"
                    onClick={() => option.handleConfirm()}
                  >
                    {option.confirm}
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={() => option.handleCancel()}
                    ref={cancelButtonRef}
                  >
                    {option.cancel}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}, []);
export default ModalConfirmation;
