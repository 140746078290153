import React, { memo, useContext, useEffect, useRef, useState } from "react";
import { makeStyles } from "@mui/styles";
import { SettingContext } from "../../context/SettingContext";
import EditForm from "./EditForm";
import { EllipsisVerticalIcon } from "@heroicons/react/24/solid";
import { CheckIcon } from "lucide-react";
import ModalConfirmation from "../common/ModalConfirmation";
import { AuthContext } from "../../context/AuthContext";
import { encryptAndStoreData } from "../common/encryptedStorage";

const useStyles = makeStyles(() => ({
  containerItem: {
    width: "100%",
    backgroundColor: "white",
    boxShadow: "rgb(0 0 0/10%) 0px 4px 4px",
    borderRadius: 8,
    cursor: "pointer",
    marginTop: 8,
    textAlign: "left",
    fontSize: 16,
    boxSizing: "border-box",
    borderLeft: "6px solid transparent",
    transition: "unset",
    "&:hover": {
      borderLeft: "6px solid rgb(211,211,211)",
    },
  },
  containerItemFocused: {
    width: "100%",
    backgroundColor: "#e0f2fe",
    boxShadow: "rgb(0 0 0/10%) 0px 4px 4px",
    borderRadius: 8,
    cursor: "pointer",
    marginTop: 8,
    textAlign: "left",
    boxSizing: "border-box",
    borderLeft: "6px solid #38bdf8",
    transition: "unset",
  },
  containerItemLeft: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    position: "relative",
    padding: " 18px 0px 18px 14px",
    overflowWrap: "break-word",
    width: "75%",
  },
  containerItemRight: {
    display: "flex",
    alignItems: "center",
  },
  itemTick: {
    display: "inline-block",
    width: 22,
    height: 22,
    border: "2px solid rgb(223,223,223)",
    backgroundColor: "rgb(223,223,223)",
    marginRight: 10,
    borderRadius: "50%",
    "&:hover": {
      opacity: 0.7,
    },
  },
  itemTicked: {
    display: "inline-block",
    width: 22,
    height: 22,
    marginRight: 10,
    borderRadius: "50%",
    zIndex: 999999999,

    "&:hover": {
      opacity: 0.7,
    },
    border: "2px solid rgb(217,85,80)",
    backgroundColor: "rgb(217,85,80)",
  },
  itemTitle: {
    color: "rgb(85,85,85)",
    fontWeight: "bold",
    width: "85%",
    overflow: "hidden",
    alignItems: "center",
    lineHeight: "1.5em",
  },
  countPomo: {
    display: "inline-block",
    color: "rgb(187,187,187)",
    fontWeight: "bold",
    marginRight: 18,
    width: 40,
    fontSize: 18,
    textAlign: "right",
  },
  totalPomo: {
    fontSize: 14,
    marginLeft: 2,
  },
  editButtonContainer: {
    width: 30,
    paddingRight: 14,
    textAlign: "center",
  },
  editButton: {
    cursor: "pointer",
    border: "1px solid rgb(223,223,223)",
    borderRadius: 4,
    padding: "2px 4px",
    backgroundColor: "white",
    width: 25,
    "&:hover": {
      backgroundColor: "rgb(211,211,211)",
    },
    zIndex: 1000000000,
  },
  edit: {
    opacity: 0.4,
    marginTop: 3,
    color: "black",
  },
}));
const classNames = (...classes) => {
  return classes.filter(Boolean).join(" ");
};
const TaskItem = ({ tasks, setTasks }) => {
  const newTask = [...tasks];
  const { user } = useContext(AuthContext);
  const settingInfo = useContext(SettingContext);
  const [showEditForm, setShowEditForm] = useState(false);
  const [editForm, setEditForm] = useState({});
  const [toggleDone, setToggleDone] = useState(false);
  const currentUserSession = settingInfo.currentUserSession;
  const modalRef = useRef(null);
  let taskSelect;

  const editTask = (task) => {
    if (!task.taskName) {
      return;
    }
    const newList = tasks.map((item) => {
      if (item.id === task.id) {
        const updateItem = { ...task, completeEdit: true };
        return updateItem;
      }
      return item;
    });
    setTasks(newList);
    encryptAndStoreData("current-local-sessions", newList);
  };

  const deleteTask = (id) => {
    const newList = [...tasks].filter((task) => task.id !== id);
    setTasks(newList);
    encryptAndStoreData("current-local-sessions", newList);
  };

  useEffect(() => {
    if (tasks.length > 0 && settingInfo.focusTaskId.id != null) {
      const currentItem = newTask.filter(
        (item) => item.id === settingInfo.focusTaskId.id
      );
      currentItem[0].currentPomo = settingInfo.focusTaskId.count;
      setTasks(newTask);
    }
  }, [settingInfo.focusTaskId]);

  const onClickSwitchFocusTask = (task) => {
    taskSelect = task;
    if (!user) {
      settingInfo.setFocusTaskId({
        count: task.currentPomo,
        id: task.id,
      });
      settingInfo.setCurrentUserSession({ ...task });
      return;
    }
    console.log("task", task);
    console.log("currentUserSession", currentUserSession);
    if (task.id !== currentUserSession.id) {
      if (currentUserSession.taskStartAt && !currentUserSession.done) {
        modalRef.current.open(switchFocusTaskProgressOption);
      } else {
        settingInfo.setFocusTaskId({
          count: task.currentPomo,
          id: task.id,
        });
        settingInfo.setCurrentUserSession({ ...task });
      }
    } else {
      settingInfo.setFocusTaskId({
        count: task.currentPomo,
        id: task.id,
      });
      settingInfo.setCurrentUserSession({ ...task });
    }
  };
  const switchFocusTaskOption = {
    title: "Switch to new task?",
    content: "Do you want to switch to new task?",
    handleConfirm: () => {
      modalRef.current.close();
      handleSwitchFocusTask();
    },
    handleCancel: () => {
      modalRef.current.close();
    },
    confirm: "Yes, switch",
    cancel: "No",
    icon: "question",
  };
  const switchFocusTaskProgressOption = {
    title: "Which option do you want to switch?",
    content:
      "Switch to new task with current progress, or early finish current task and start a new progress for new task?",
    handleConfirm: () => {
      modalRef.current.close();
      handleSwitchFocusTaskWithCurrentProgress(taskSelect);
    },
    handleCancel: () => {
      modalRef.current.close();
      handleSwitchFocusTaskWithNewProgress(taskSelect);
    },
    icon: "question",
    confirm: "Switch with current progress",
    cancel: "Start new progress",
  };
  const handleSwitchFocusTask = () => {
    modalRef.current.open(switchFocusTaskProgressOption);
  };

  const handleSwitchFocusTaskWithCurrentProgress = (task) => {
    let currentTask = tasks.find((task) => task.id === currentUserSession.id);
    console.log("currentTask", currentTask);

    task.taskStartAt = currentTask.taskStartAt;
    settingInfo.setFocusTaskId({
      count: task.currentPomo,
      id: task.id,
    });
    settingInfo.setCurrentUserSession({ ...task });
  };
  const handleSwitchFocusTaskWithNewProgress = (task) => {
    let currentUserSession = settingInfo.currentUserSession;
    let currentTask = tasks.find((task) => task.id === currentUserSession.id);
    currentTask.currentPomo += 1;
    currentTask.done = currentTask.currentPomo >= currentTask.estimatedPomo;
    settingInfo.setFocusTaskId({
      count: task.currentPomo,
      id: task.id,
    });
    settingInfo.setCurrentUserSession({ ...task });
    settingInfo.setResetTimerFlag(true);
  };

  const handleMarkDoneTask = (task) => {
    task.done = !task.done;
  };
  const classes = useStyles();
  return (
    <>
      {tasks.map((task, index) => (
        <div key={index}>
          {task.completeEdit === true && (
            <div
              className={
                task.id === settingInfo.focusTaskId.id
                  ? classes.containerItemFocused
                  : classes.containerItem
              }
              key={task.id}
              onClick={() => onClickSwitchFocusTask(task)}
            >
              <div className="flex w-full">
                <div className="p-3.5 flex flex-col space-y-3 cursor-pointer w-full">
                  {/* <div
                    className={
                      task.done === true ? classes.itemTicked : classes.itemTick
                    }
                    onClick={() => handleClickDoneItem(task)}
                  >
                    <DoneIcon
                      sx={{ width: 22, height: 22, margin: 0, border: "none" }}
                    />
                  </div> */}
                  <div className="flex w-full justify-between items-center">
                    <div className="flex space-x-2">
                      <span
                        className={`text-black font-medium text-md flex ${
                          task.done && "line-through"
                        }`}
                      >
                        {task.taskName}
                      </span>
                      <div
                        className="group z-8"
                        onClick={() => handleMarkDoneTask(task)}
                      >
                        <CheckIcon
                          className={`h-6 w-6 ${
                            task.done
                              ? "bg-sky-400 text-white border-sky-400"
                              : "text-sky-400 bg-white border-sky-300"
                          }  border  rounded-full p-1
                          group-hover:bg-sky-300 group-hover:text-white `}
                        />
                      </div>
                    </div>

                    <div className="flex flex-row space-x-1 ">
                      <span className="text-gray-600 text-normal font-medium">
                        {task.currentPomo}
                        <span className="text-gray-800 text-md font-medium">
                          {""}/{task.estimatedPomo} pomos
                        </span>
                      </span>
                      <div>
                        <EllipsisVerticalIcon
                          onClick={() => {
                            setShowEditForm(true);
                            setEditForm((prev) => ({
                              ...prev,
                              task: task,
                              id: task.id,
                            }));
                            task.completeEdit = false;
                          }}
                          className="h-6 w-6 hover:fill-sky-300 cursor-pointer"
                        />
                      </div>
                    </div>
                  </div>
                  <span className="text-gray-400 text-md font-normal flex text-clip overflow-hidden pr-4">
                    {task?.taskDescription?.split("\n").map((line, index) => (
                      <React.Fragment key={index}>
                        {line}
                        <br />
                      </React.Fragment>
                    ))}
                  </span>
                  <span
                    style={{ background: `${task.category.color}` }}
                    className={
                      "w-fit text-white text-sm font-medium h-fit py-1 px-2 rounded"
                    }
                  >
                    {task.category.label}
                  </span>
                </div>
              </div>
            </div>
          )}
          <ModalConfirmation ref={modalRef} />
          {showEditForm && (
            <>
              {editForm.id === task.id && (
                <EditForm
                  onSubmit={editTask}
                  tasks={editForm.task}
                  setShowEditForm={setShowEditForm}
                  deleteTask={deleteTask}
                  // editRef={editRef}
                />
              )}
            </>
          )}
        </div>
      ))}
    </>
  );
};

export default memo(TaskItem, (prevProps, nextProps) => {
  return prevProps === nextProps;
});
